@import '~antd/dist/antd.css';

/* remove browser scroll bar */
html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

main {
  margin-top: 64px;
}

.ant-timeline-item-last {
  padding-bottom: 0!important;
}

.ant-layout {
  background-color: #fff!important;
  background: #fff!important;
}

/* change nav menu text & bottom border color */
.ant-menu-item > a {
  color: rgba(103, 103, 103, 0.65);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f1f1f1!important; 
}

.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
  color: #000!important;
  border-bottom: 2px solid #dfdfdf!important;

  /* & > a {
    color: #000!important;
  } */
}

.ant-menu-item:hover > a {
  color: #000!important;
}

.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #000!important;
  border-bottom: 2px solid #dfdfdf!important;
}

.ant-menu-item.ant-menu-item-selected {
  color: #000;
  border-bottom: 2px solid #dfdfdf!important;

  /* .ant-menu-sub & {
    border-bottom: none!important;
  } */

  /* & > a {
    color: #000!important;
  } */
}

.ant-menu-item.ant-menu-item-selected > a {
  color: #000;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* iPhone, Android rules here */
  .ant-layout-header {
    padding: 0 20px;
    height: 57px;
    line-height: 57px;
  }
  .ant-back-top {
    bottom: 20px;
  }
}